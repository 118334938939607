import BpkBreakpoint, {
  BREAKPOINTS,
} from '@skyscanner/backpack-web/bpk-component-breakpoint';

export type WithBreakpointConfigProps = {
  breakpointConfig: BreakpointConfig;
};

export type BreakpointConfig = {
  perSection: number;
};

// For each breakpoint, defines how many items to show per section, and which images are "tall"
export const breakpointConfigs: { [key: string]: BreakpointConfig } = {
  mobile: {
    perSection: 3,
  },
  smallTabletOnly: {
    perSection: 4,
  },
  smallTabletToDesktop: {
    perSection: 6,
  },
  aboveDesktop: {
    perSection: 8,
  },
};

/**
 * A higher-order-function that provides the config required for the current breakpoint
 * The config can be found at `props.config` of the wrapped component
 * @param {React.Component} WrappedComponent The component to wrap
 * @returns {React.Component} The component with breakpoint config
 */
function withBreakpointConfig<
  T extends WithBreakpointConfigProps = WithBreakpointConfigProps,
>(WrappedComponent: React.ComponentType<T>) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithBreakpointConfig = (
    props: Omit<T, keyof WithBreakpointConfigProps>,
  ) => (
    <BpkBreakpoint query={BREAKPOINTS.ABOVE_DESKTOP} matchSSR>
      {(isAboveDesktop: boolean) => (
        <BpkBreakpoint query={BREAKPOINTS.SMALL_TABLET_ONLY}>
          {(isSmallTabletOnly: boolean) => (
            <BpkBreakpoint query={BREAKPOINTS.MOBILE}>
              {(isMobile: boolean) => {
                let config = breakpointConfigs.smallTabletToDesktop;

                if (isMobile) {
                  config = breakpointConfigs.mobile;
                }

                if (isSmallTabletOnly) {
                  config = breakpointConfigs.smallTabletOnly;
                }

                if (isAboveDesktop) {
                  config = breakpointConfigs.aboveDesktop;
                }

                return (
                  <WrappedComponent
                    {...(props as T)}
                    breakpointConfig={config}
                  />
                );
              }}
            </BpkBreakpoint>
          )}
        </BpkBreakpoint>
      )}
    </BpkBreakpoint>
  );

  ComponentWithBreakpointConfig.displayName = `withBreakpointConfig(${displayName})`;

  return ComponentWithBreakpointConfig;
}

export default withBreakpointConfig;
